<template>
  <div>
    <div class="wrapper">
      <div class="container-fluid d-flex">
        <aside>
          <div class="side-navbar animate__animated animate__slideInLeft">
            <div class="navbar-top d-flex">
              <a href="../dashboard"
                ><img
                  src="../../assets/images/logo.png"
                  alt="Lek_Auto_logo"
                  class="img-fluid"
              /></a>

              <span><i class="fa-solid fa-bars btn" id="btn"></i></span>
            </div>
            <div class="navbar-links">
              <ul>
                <div v-for="menu in myMenus" :key="menu.ID">
                  <div class="dropdown" v-if="menu.SUBMODULE">
                    <a href="#" v-if="hasMenuAccess(menu)">
                      <li class="dropbtn">
                        <i class="fa-solid fa-box-archive nav-icn"></i>
                        <span class="nav-itm">{{ menu.MODULE }}</span>
                        <i class="fa-solid fa-angle-right icon-2"></i>
                      </li>
                    </a>
                    <div class="dropdown-content">
                      <router-link
                        v-for="sub in menu.SUBMODULE"
                        :key="sub.ID"
                        :to="sub.VIEW_URL"
                        v-if="
                          sub.ACCESS.FULL_ACCESS ||
                          sub.ACCESS.VIEW_ACCESS ||
                          sub.ACCESS.CREATE_ACCESS
                        "
                      >
                        <span class="nav-itm" @click="beforeRoute(sub)">{{
                          sub.NAME
                        }}</span>
                      </router-link>
                    </div>
                  </div>

                  <router-link
                    v-else-if="
                      menu.ACCESS.FULL_ACCESS ||
                      menu.ACCESS.VIEW_ACCESS ||
                      menu.ACCESS.CREATE_ACCESS
                    "
                    :to="menu.VIEW_URL || menu.ADD_URL"
                  >
                    <li>
                      <i class="fa-solid fa-house-chimney nav-icn"></i>
                      <span class="nav-itm">{{ menu.MODULE }}</span>
                    </li>
                  </router-link>
                </div>
              </ul>
            </div>
          </div>
        </aside>

        <div class="main-cont animate__animated animate__slideInRight">
          <div class="container-fluid">
            <div class="main-cont-nav mt-3">
              <ul class="nav justify-content-end">
                <!-- <li class="nav-item">
                                    <a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#user1modal6"><i class="fa-solid fa-gear"></i></a>
                                </li>
                                <li class="nav-item">
                                    <div class="dropdown">
                                        <a class="nav-link" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-solid fa-bell"></i></a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li>
                                                <a class="dropdown-item" href="#"> new Message <span>From Akash</span></a><span class="time-updt">updated 10 minutes ago</span>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="#"> new Album <span>By Tylor Swift</span></a><span class="time-updt">updated 10 minutes ago</span>
                                            </li>
                                            <li><a class="dropdown-item" href="#">Payment Successfully Completed</a><span class="time-updt">updated 10 minutes ago</span></li>
                                        </ul>
                                    </div>
                                </li> -->
                <!-- <li class="nav-item">
                                    <div class="profile-info-wrap">
                                        <div class="profile-info">
                                            <ul class="profile-cred-list">
                                                <li class="profile-name">Akash Roy</li>
                                                <li class="profile-desig">User</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> -->
                  <li class="nav-item">
                    <i class="fa-solid fa-bars" id="shownav"></i>
                  </li>
                  <li class="nav-item">
                  <div class="profile-info-wrap">
                    <div class="profile-info">
                      <ul class="profile-cred-list">
                        <li class="profile-name">{{ auth_user.name }}</li>
                        <li class="profile-desig">{{ auth_user.role }}</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="nav-item profl-dropdwn">
                  <div class="dropdown2">
                    <a href="#">
                      <li class="dropbtn">
                        <span class="nav-itm"
                          ><i class="fa fa-user-circle" aria-hidden="true"></i
                        ></span>
                      </li>
                    </a>
                    <div class="dropdown-content2">
                      <ul class="pop-profile-list">
                        <router-link class="nav-itm" to="/profile"
                          ><li>
                            <i class="fa-solid fa-user me-2"></i>Profile
                          </li></router-link
                        >
                        <a @click="signOut" class="nav-itm"
                          ><li>
                            <i
                              class="fa-solid fa-arrow-right-from-bracket me-2"
                            ></i
                            >Logout
                          </li></a
                        >
                      </ul>
                    </div>
                    <!-- <div id="profile-dropdown" class="dropdown-contnt shadow-main">
                                                <ul class="pop-profile-list">
                                                   <router-link class="ps-3" to="/profile">
                      <i class="fa-solid fa-user me-2"></i>Profile
                    </router-link>
                   
                                                   
                                                    <li><a href="#"  @click.prevent="signOut" class="ps-3"><i class="fa-solid fa-arrow-right-from-bracket me-2"></i> Logout</a>
                                                    </li>
                                                </ul>
                                            </div> -->
                  </div>
                </li>
              </ul>
            </div>
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here  -->
    <div
      class="modal modal-right fade"
      id="user1modal6"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body"></div>
          <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
$(document).ready(function(){
  $("#btn").click(function(){
    $(".side-navbar").hide();
  });
  $("#shownav").click(function(){
    $(".side-navbar").show();
  });
});

import apiEndPoints from "../../endpoints.js";

export default {
  data() {
    return {
      vechile_model_details: [],
      auth_user: {
        name: null,
        role: null,
      },
    };
  },
  mounted() {
    $(document).ready(function(){
  $("#btn").click(function(){
    $(".side-navbar").hide();
  });
  $("#shownav").click(function(){
    $(".side-navbar").show();
  });
});
    $(document).ready(function () {
      window.onclick = function (event) {
        if (!event.target.matches(".dropbtn")) {
          var dropdowns = document.getElementsByClassName("dropdown-contnt");
          var i;
          for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
              openDropdown.classList.remove("show");
            }
          }
        }
      };
    });

    this.$nextTick(() => {
      let user = localStorage.getItem("lek_user_details");
      if (user) {
        user = JSON.parse(user);
      }
      this.auth_user.role = user.SystemRoleName || "User";
      this.auth_user.name = user.FirstName;
    });
  },
  methods: {
    signOut() {
      $cookies.remove("_token");
      localStorage.removeItem("lek_user_details");
      this.$router.push({ name: "Login" });
    },
    profileFunction() {
      document.getElementById("profile-dropdown").classList.toggle("show");
    },
    hasMenuAccess(menu) {
      let result = false;
      menu.SUBMODULE.forEach((sub) => {
        if (
          sub.ACCESS.FULL_ACCESS ||
          sub.ACCESS.VIEW_ACCESS ||
          sub.ACCESS.CREATE_ACCESS
        ) {
          result = true;
        }
      });
      return result;
    },
    beforeRoute(sub) {
      localStorage.setItem("activeMenu", JSON.stringify(sub));
    },
  },
  computed: {
    myMenus() {
      let menu = localStorage.getItem("my_menu");
      if (!menu) {
        return [];
      }
      return JSON.parse(menu);
    },
  },
};
</script>
