var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container-fluid d-flex"},[_c('aside',[_c('div',{staticClass:"side-navbar animate__animated animate__slideInLeft"},[_vm._m(0),_c('div',{staticClass:"navbar-links"},[_c('ul',_vm._l((_vm.myMenus),function(menu){return _c('div',{key:menu.ID},[(menu.SUBMODULE)?_c('div',{staticClass:"dropdown"},[(_vm.hasMenuAccess(menu))?_c('a',{attrs:{"href":"#"}},[_c('li',{staticClass:"dropbtn"},[_c('i',{staticClass:"fa-solid fa-box-archive nav-icn"}),_c('span',{staticClass:"nav-itm"},[_vm._v(_vm._s(menu.MODULE))]),_c('i',{staticClass:"fa-solid fa-angle-right icon-2"})])]):_vm._e(),_c('div',{staticClass:"dropdown-content"},_vm._l((menu.SUBMODULE),function(sub){return (
                        sub.ACCESS.FULL_ACCESS ||
                        sub.ACCESS.VIEW_ACCESS ||
                        sub.ACCESS.CREATE_ACCESS
                      )?_c('router-link',{key:sub.ID,attrs:{"to":sub.VIEW_URL}},[_c('span',{staticClass:"nav-itm",on:{"click":function($event){return _vm.beforeRoute(sub)}}},[_vm._v(_vm._s(sub.NAME))])]):_vm._e()}),1)]):(
                    menu.ACCESS.FULL_ACCESS ||
                    menu.ACCESS.VIEW_ACCESS ||
                    menu.ACCESS.CREATE_ACCESS
                  )?_c('router-link',{attrs:{"to":menu.VIEW_URL || menu.ADD_URL}},[_c('li',[_c('i',{staticClass:"fa-solid fa-house-chimney nav-icn"}),_c('span',{staticClass:"nav-itm"},[_vm._v(_vm._s(menu.MODULE))])])]):_vm._e()],1)}),0)])])]),_c('div',{staticClass:"main-cont animate__animated animate__slideInRight"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"main-cont-nav mt-3"},[_c('ul',{staticClass:"nav justify-content-end"},[_vm._m(1),_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"profile-info-wrap"},[_c('div',{staticClass:"profile-info"},[_c('ul',{staticClass:"profile-cred-list"},[_c('li',{staticClass:"profile-name"},[_vm._v(_vm._s(_vm.auth_user.name))]),_c('li',{staticClass:"profile-desig"},[_vm._v(_vm._s(_vm.auth_user.role))])])])])]),_c('li',{staticClass:"nav-item profl-dropdwn"},[_c('div',{staticClass:"dropdown2"},[_vm._m(2),_c('div',{staticClass:"dropdown-content2"},[_c('ul',{staticClass:"pop-profile-list"},[_c('router-link',{staticClass:"nav-itm",attrs:{"to":"/profile"}},[_c('li',[_c('i',{staticClass:"fa-solid fa-user me-2"}),_vm._v("Profile ")])]),_c('a',{staticClass:"nav-itm",on:{"click":_vm.signOut}},[_vm._m(3)])],1)])])])])]),_c('router-view')],1)])])]),_vm._m(4)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-top d-flex"},[_c('a',{attrs:{"href":"../dashboard"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/images/logo.png"),"alt":"Lek_Auto_logo"}})]),_c('span',[_c('i',{staticClass:"fa-solid fa-bars btn",attrs:{"id":"btn"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('i',{staticClass:"fa-solid fa-bars",attrs:{"id":"shownav"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"#"}},[_c('li',{staticClass:"dropbtn"},[_c('span',{staticClass:"nav-itm"},[_c('i',{staticClass:"fa fa-user-circle",attrs:{"aria-hidden":"true"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('i',{staticClass:"fa-solid fa-arrow-right-from-bracket me-2"}),_vm._v("Logout ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal modal-right fade",attrs:{"id":"user1modal6","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-xl",staticStyle:{"width":"30%"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"btn-normal modal-close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"fa fa-angle-right",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"modal-body"})])])])}]

export { render, staticRenderFns }